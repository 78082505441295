import {api, graph2, graph_vars, test, test2} from '../utils/shared'


export const promos = async(fields, token) => {
    let q = `{promotional_codes {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const promo = async(code_id, fields, token) => {
    let q = `{promotional_code(code_id: ${code_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const promo_add = async(code, cookie_verification, expire, amount, discount_type, departure_from, departure_to, minimum_cost, number_of_uses, fields, token) => {
    let q = `mutation {promotional_code_add(code: "${code}", cookie_verification: "${cookie_verification}", expire: "${expire}", amount: ${amount}, 
        discount_type: ${discount_type}, departure_from: "${departure_from}", departure_to: "${departure_to}", minimum_cost: ${minimum_cost}, number_of_uses: ${number_of_uses}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const promo_update = async(code_id, code, cookie_verification, expire, amount, discount_type, departure_from, departure_to, minimum_cost, number_of_uses, fields, token) => {
    let q = `mutation {promotional_code_update(code_id: ${code_id}, code: "${code}", cookie_verification: "${cookie_verification}", expire: "${expire}", amount: ${amount}, 
        discount_type: ${discount_type}, departure_from: "${departure_from}", departure_to: "${departure_to}", minimum_cost: ${minimum_cost}, number_of_uses: ${number_of_uses}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const promo_remove = async(code_id, fields, token) => {
    let q = `mutation {promotional_code_remove(code_id: ${code_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}