import React, {useEffect, useState} from 'react';
import AccommodationHits from '../../../components/charts/acc/acc_hits'
import AccommodationHitsMonth from '../../../components/charts/acc/acc_hites_month'
import YMDPicker from '../../../components/ymd_picker'


const AccStats = ({user_token, set_menu, authorize}) => {


    const [selected_destinations, setSelectedDestinations] = useState(null)

    const [selected_regions, setSelectedRegions] = useState(null)

	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)


	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

    const change_destination = (v) => {
        let val = Number(v)
        if(val == -1){
            setSelectedDestinations(null)
        }
        else{
            setSelectedDestinations([val]);
        }
    }


	useEffect(() =>{
		authorize(1);
		const lnks = [{'url': '/stats', 'name': 'Stats'}, {'url': '/stats/acc', 'name': 'Accommodation'}]
		set_menu(lnks)
    }, [])


    


	return (


        <>
        
        <div className='fitler_sec'>

            {/* <div className='filter_field'>
                <div>
                    Destination 
                </div>
                <div>
                    <select onChange={(e) => change_destination(e.target.value)}>
                        <option value="-1">All</option>
                        <option value="152" >Portugal</option>
                    </select>
                </div>
            </div> */}



            <div style={{height: '105px'}}>
					<div>
						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								Date from 
							</div>
							<div>
                            <YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker> 
							</div>
						</div>
						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								To
							</div>
							<div>
                            <YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
							</div>
						</div>
					</div>
					</div>


        </div>

        <div style={{display:'flex', flexWrap:'wrap', margin:'50px 50px 50px 50px'}}>


	
		<div>
            <div style={{textAlign:'center'}}>Accommodation hits share</div>
             <AccommodationHits destination_ids={selected_destinations} region_ids={selected_regions} date_from={selected_from_date} date_to={selected_to_date} token={user_token} ></AccommodationHits>
        </div>

        <div>
            <div style={{textAlign:'center'}}>Accommodation hits by month</div>
            <AccommodationHitsMonth destination_ids={selected_destinations} region_ids={selected_regions} date_from={selected_from_date} date_to={selected_to_date} token={user_token} ></AccommodationHitsMonth>
        </div>

        </div>
        </>



	);
};

export default AccStats;
