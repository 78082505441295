import React, {useEffect} from 'react';
import { Routes, Route, BrowserRouter,  } from 'react-router-dom';
import LogIn from './containers/log_in'
import Challenge from './containers/log_in/challenge'
import ResetReq from './containers/log_in/reset_req'
import ResetPassword from './containers/log_in/reset_password'
import Home from './containers/home'
import About from './containers/about'
import Bookings from './containers/bookings'
import BookingView from './containers/booking_view'
import Enquiries from './containers/enquiries'
import EnquiryView from './containers/enquiry_view'
import Basket from './containers/basket'
import BaskeView from './containers/basket_view'
import Component_Test from './containers/component_test'
import AuditHome from './containers/audit'
import AccPrices from './containers/audit/acc_prices'
import OfferPrices from './containers/audit/offer_prices'
import FlightPrices from './containers/audit/flight_prices'

import Margins from './containers/margins'
import MarginAdd from './containers/margin_add'

import Discounts from './containers/discounts'
import DiscountAdd from './containers/discount_add'

import Deposits from './containers/deposits'
import DepositAdd from './containers/deposit_add'

import Promos from './containers/promos'
import PromoAdd from './containers/promo_add'

import Users from './containers/users'
import UsersAdd from './containers/users_add'

import Nav from './components/nav'

import Stats from './containers/stats'

import AccStats from './containers/stats/acc'

import DestStats from './containers/stats/destination'
import DestStatsMonth from './containers/stats/destination/month'
import DestStatsDepPoint from './containers/stats/destination/dep_point'

import JourneyStats from './containers/stats/journey'

import BookingCountStats from './containers/stats/booking'
import BookingCostStats from './containers/stats/booking/avg_cost'

import ProductFeeds from './containers/product_feeds'
import ProductFeed from './containers/product_feed_add'

import {sub_user_client, user_log_in, user_two_fac_auth, auth} from './service/sub_user'
import {cookie, getCookie, expire_cookie} from './utils/shared'

import '../src/App.css';

class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user_id: 0,
        role_id: 0,
        user_logged_in: false,
        two_challenge: false,
        user_name: null,
        user_token: '',
        log_in_attempts: 0,
        sub_user_ref: '',
        log_in_err: false,
        menu_links: []
      };
    }


      

    componentDidMount(){
      let uli_tk = getCookie('usign',true);

      window.setInterval(() => {

        if(uli_tk != null){
          sub_user_client().then((d) => {
            if(d == null){
              expire_cookie("usign")
              this.setState({user_logged_in: false, user_token: '', sub_user_ref: '', role_id: 0});
              window.location = '/';
            }
            else{
              if(d.data.client_subuser.result == null){
                expire_cookie("usign")
                this.setState({user_logged_in: false, user_token: '', sub_user_ref: ''});
                window.location = '/';
                return
              }
            }
          })
        }

      }, 30000)

      if(uli_tk != null){

        let role_id = 1;
        let su = sub_user_client().then((d) => {
          if(d == null){
            expire_cookie("usign")
            this.setState({user_logged_in: false, user_token: '', sub_user_ref: ''});
            window.location = '/';
          }
          else{

            if(d.data.client_subuser.result == null){
              expire_cookie("usign")
              this.setState({user_logged_in: false, user_token: '', sub_user_ref: ''});
              window.location = '/';
              return
            }

            let user_a_token = uli_tk['token'];
            this.setState({user_logged_in: true, user_token: user_a_token, sub_user_ref: d.data.client_subuser.result.user_ref, role_id: d.data.client_subuser.result.role_id})
            this.ini_menu(d.data.client_subuser.result.role_id);
      
         
          }
        })     
      }
    }




    log_in = async(user_name, password) =>{
      const {log_in_attempts} = this.state;
      user_log_in(user_name, password).then((r) => {
         let la = 0;
         if(r == null){
            la = log_in_attempts + 1;
            this.setState({user_logged_in: false, log_in_err: true});
         }
         else{

            if(r["two_factor"]){
              this.setState({two_challenge: true, user_id: r["user_id"], user_name: r["username"]})
              return;
            }

            let cd = JSON.stringify(r)
            cookie('usign', cd, false, 28);
            window.location = '/';
         }
      
      });
    }

    ini_menu = (role_id) => {

      if(role_id == 0){
        this.set_menu_links([{'url': '/', 'name': 'Home (BETA)', 'new': true}, {'url': '/bookings', 'name': 'Bookings'} , {'url': '/baskets', 'name': 'Customer Reference (BETA)', 'new': true}, 
        {'url':'/deposits', 'name': 'Deposits'}, {'url':'/discounts', 'name': 'Discounts'}, {'url': '/margins', 'name': 'Margins'}, {'url':'/product_feeds', 'name': 'Product Feeds'}, {'url':'/promos', 'name': 'Promotional Codes'}, 
        {'url':'/stats', 'name': 'Stats (BETA)', 'new': true}, {'url':'/users', 'name': 'Users'} ])
      }
      else if(role_id == 1){
        this.set_menu_links([{'url': '/', 'name': 'Home (BETA)', 'new': true}, {'url': '/bookings', 'name': 'Bookings'} , {'url': '/baskets', 'name': 'Customer Reference (BETA)', 'new': true}, 
        {'url':'/deposits', 'name': 'Deposits'}, {'url':'/discounts', 'name': 'Discounts'}, {'url': '/margins', 'name': 'Margins'}, {'url':'/product_feeds', 'name': 'Product Feeds'},  {'url':'/promos', 'name': 'Promotional Codes'}, 
        {'url':'/stats', 'name': 'Stats (BETA)', 'new': true}, {'url':'/users', 'name': 'Users'} ])
      }
      else{
        this.set_menu_links([{'url': '/', 'name': 'Home'}, {'url': '/bookings', 'name': 'Bookings'} , {'url': '/baskets', 'name': 'Customer Reference'}])
      }
    }

    access_auth = (req_role) => {
      // let uli_tk = getCookie('usign',true);
      // let role_id = uli_tk["role_id"];
      // if(role_id > req_role){
      //   window.location = '/';
      // }

      auth(req_role, this.state.user_token).then((r) => {
        if(r != true){
          window.location = '/';
        }
      });

    }

    challenge_result = (r) => {

      if(r == null){
        return;
      }

      let cd = JSON.stringify(r)
      cookie('usign', cd, false, 28);
      window.location = '/';

    }

    log_out = () => {
      cookie('usign', null, false, -100);
      window.location = '/';
    }

    set_menu_links =(lnks) => {
      this.setState({menu_links: lnks})
    }



    render() {

      
      const {user_logged_in, log_in_attempts, two_challenge, log_in_err} = this.state

      let log_in_message = null;
      if(log_in_attempts > 0){
        log_in_message = <div>Username / Password incorrect</div>
      }


      if(!user_logged_in && two_challenge){
        return(
          <div className='app' style={{width:'100%'}}>
            <BrowserRouter>
            <Routes>
                <Route path="/" element={<Challenge {...this.props} user_id={this.state.user_id} user_name={this.state.user_name} update_log_in={(r) => this.challenge_result(r)} ></Challenge>} exact />
            </Routes>
            {log_in_message}
            </BrowserRouter>
          </div>
        )
      }

      if(!user_logged_in){
        return(
          <div className='app' style={{width:'100%'}}>
            <BrowserRouter>
            <Routes>
                <Route path="/" element={<LogIn {...this.props} isError={log_in_err} log_in_func={(u, p) => this.log_in(u, p)} ></LogIn>} exact />
                <Route path="/reset_req" element={<ResetReq {...this.props} ></ResetReq>} exact />
                <Route path="/set_password" element={<ResetPassword {...this.props} log_in_func={(u, p) => this.log_in(u, p)} ></ResetPassword>} exact />
            </Routes>
            {log_in_message}
            </BrowserRouter>
          </div>
        )
      }

  

      return(
        <div className='app' style={{width:'100%'}}>

    

            <BrowserRouter>
            <Nav user_token={this.state.user_token} usr_ref={this.state.sub_user_ref} links={this.state.menu_links} log_out_func={() => this.log_out()}></Nav>

              <Routes>
                  <Route path="/" element={<Home user_token={this.state.user_token} set_menu={(x) => this.set_menu_links(x)}></Home>} exact />

                  <Route path="/bookings" element={<Bookings user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Bookings>} exact />
                  <Route path="/bookings/view" element={<BookingView user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></BookingView>} exact />
                  <Route path="/baskets" element={<Basket user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Basket>} exact />
                  <Route path="/basket/view" element={<BaskeView user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></BaskeView>} exact />
                  {/* <Route path="/components/manage" render={(props) => (<Component_Test {...props} user_token={this.state.user_token}></Component_Test>)} exact /> */}
                  {/* <Route path="/audit" render={(props) => (<AuditHome {...props} user_token={this.state.user_token}></AuditHome>)} exact />
                  <Route path="/audit/acc_prices" render={(props) => (<AccPrices {...props} user_token={this.state.user_token}></AccPrices>)} exact />
                  <Route path="/audit/flight_prices" render={(props) => (<FlightPrices {...props} user_token={this.state.user_token}></FlightPrices>)} exact />
                  <Route path="/audit/offer_prices" render={(props) => (<OfferPrices {...props} user_token={this.state.user_token}></OfferPrices>)} exact /> */}
                  <Route path="/margins" element={<Margins user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Margins>} exact />
                  <Route path="/margins/add" element={<MarginAdd user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></MarginAdd>} exact />
                  
                  <Route path="/discounts" element={<Discounts user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Discounts>} exact />
                  <Route path="/discounts/add" element={<DiscountAdd user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></DiscountAdd>} exact />

                  <Route path="/deposits" element={<Deposits user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Deposits>} exact />
                  <Route path="/deposits/add" element={<DepositAdd user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></DepositAdd>} exact />

                  <Route path="/promos" element={<Promos user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Promos>} exact />
                  <Route path="/promos/add" element={<PromoAdd user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></PromoAdd>} exact />

                  <Route path="/users" element={<Users user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Users>} exact />
                  <Route path="/users/add" element={<UsersAdd user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></UsersAdd>} exact />

                  <Route path="/stats" element={<Stats user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Stats>} exact />

                  <Route path="/stats/acc" element={<AccStats user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></AccStats>} exact />

                  <Route path="/stats/destination" element={<DestStats user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></DestStats>} exact />
                  <Route path="/stats/destination/month" element={<DestStatsMonth user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></DestStatsMonth>} exact />
                  <Route path="/stats/destination/departure_point" element={<DestStatsDepPoint user_token={this.state.user_token} 
                  authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></DestStatsDepPoint>} exact />

                  <Route path="/stats/journey" element={<JourneyStats user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></JourneyStats>} exact />

                  <Route path="/stats/booking" element={<BookingCountStats user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></BookingCountStats>} exact />
                  <Route path="/stats/booking/cost" element={<BookingCostStats user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></BookingCostStats>} exact />

                  <Route path="/product_feeds" element={<ProductFeeds user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></ProductFeeds>} />

                  <Route path="/product_feeds/add" element={<ProductFeed user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></ProductFeed>} />

              </Routes>
            </BrowserRouter>
        </div>
      )
    }
}



export default App