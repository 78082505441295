import React, {useEffect, useState} from 'react';
import {sub_users} from '../../service/sub_user'
import YMDPicker from '../../components/ymd_picker'
import '../enquiries/style.css';
import dayjs from 'dayjs';

const Users = ({user_token, set_menu, authorize}) => {

    const [d_view, setDataView] = useState(null);

	useEffect(() =>{

		authorize(1);

		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/users/add', 'name': 'New User'}]
		set_menu(lnks)

		let f = "id email_address username user_ref role_id enabled created"

		sub_users(user_token, f, []).then((r) =>{
			user_result(r);
		});
    }, [])
		
	const user_result = (r) => {
		if(r == null){
			return
		}	
		if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
		}
		if(r.client_subusers.error != null){
			alert(r.client_subusers.error.message)
			return
		}
		setDataView(r.client_subusers.result)
	}

	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let date_created = dayjs(x.created, 'YYYY-MM-DD HH:mm');
			let str_created = date_created.format('DD MMMM YYYY HH:mm');

			let str_enabled = 'False'
			if(x.enabled == 1){
				str_enabled = 'True'
			}

			let str_role = '';
			switch(x.role_id){
				case 0:
					str_role = 'Owner';
					break
				case 1:
					str_role = 'Admin';
					break
				case 2:
					str_role = 'Write';
					break
				case 3:
					str_role = 'Read';
					break
			}

			rows.push(<tr>
				<td>
					{x.email_address}
				</td>
				<td>
					{str_role}
				</td>
				<td>
					{str_enabled}
				</td>
				<td>
					{str_created}
				</td>
				<td>
					<a href={'/users/add?id=' + x.id}>Edit</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Email address 
					</td>
					<td>
						Role 
					</td>
					<td>
						Enabled 
					</td>
					<td>
						Created 
					</td>
					<td>
						
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}


	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

			</div>
		</section>
	);
};

export default Users;
