import {api, graph, graph2, graph_vars, graph_vars_str, getCookie} from '../utils/shared'


export const user_log_in = async(user_name, password) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/client_subuser_login",
            {"username": user_name, "password": password},
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const user_two_fac_auth = async(user_name, user_id, code) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/client_subuser_two_auth",
            {"username": user_name, "user_id": user_id, "code": code},
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
}

export const user_password_reset_request = async(email_address) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/client_subuser_reset_request",
            {"email_address": email_address},
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const auth = async(role_id, token) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/auth/" + role_id, null,
            "GET",
            (d) => {
                d.text().then((result) => {
                    if(result.toLowerCase() == "true"){
                        resolve(true);
                    }
                    resolve(false);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const user_password_reset = async(email_address, reset_token, new_password) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/client_subuser_reset",
            {"email_address": email_address,"reset_token": reset_token,"new_password": new_password},
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};

export const sub_user_client = async() => {
    let u = getCookie('usign', true);
    let q = `{client_subuser{result{id email_address username user_ref, role_id}}}`
    // graph(q, null, (r) =>{
    //     callback(r);
    // }, u.token)

    return new Promise(async (resolve, reject) => {
        await graph(q, null, (r) =>{
            if(r != null){
                resolve(r);
            }
        }, u.token)
    });
}

export const sub_user_id = async(user_id, fields, token) => {

    let q = `{client_subuser_id(id: ${user_id})
        {
            result
            {
                ${fields}
            }
            error {
                ... on Unexpected_Error {
                    message
                }
            }
        }
    }`


    let x = await graph2(q, token);
    return x
}



export const sub_users = async(token, fields, vars=[]) => {
    let p = graph_vars_str(vars)

    let vparams = p[0]
    let qparams = p[1]
    let j_params = p[2]

    let q = `query ${vparams} {
            client_subusers ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph_vars(q, j_params, token);
    return x
}

export const add_user = async(email_address, role_id, fields, token) => {
    let q = `mutation {client_subuser_add(email_address: "${email_address}", role: ${role_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const update_user = async(user_id, email_address, role_id, enabled, fields, token) => {
    let q = `mutation {client_subuser_update(email_address: "${email_address}", role: ${role_id}, enabled: ${enabled}, user_id: ${user_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

