import { graph2 } from '../utils/shared'

export const product_feed_schemas = async(fields, token) => {
    let q = `{product_feed_schemas {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const product_feeds = async(fields, token) => {
    let q = `{product_feeds {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const product_feed = async(product_feed_id, fields, token) => {
    let q = `{product_feed(product_feed_id: ${product_feed_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const product_feed_query = async(product_feed_id, fields, token) => {
    let q = `{product_feed_query(product_feed_id: ${product_feed_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const add_product_feed = async(name, destinations, regions, resorts, departing, adults, children, duration, schema_id, email_address, description, 
	client_list, departure_date, offers_by_month, de_duplicate_offers, trip_type, fields, token) => {

	let str_dep_date = null;
	if(departure_date != null){
		str_dep_date = '"' + departure_date + '"';
	}

	client_list = '[]';

    let q = `mutation {product_feed_add(name: "${name}", destinations: ${destinations}, regions: ${regions}, resorts: ${resorts}, departing: ${departing},
	adults:${adults}, children: ${children}, duration: ${duration}, schema_id: ${schema_id}, email_address: "${email_address}", description: "${description}", 
	departure_date: ${str_dep_date}, offers_by_month:${offers_by_month}, de_duplicate_offers: ${de_duplicate_offers}, trip_type: ${trip_type}, client_acc_list: ${client_list}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const update_product_feed = async(feed_id, name, destinations, regions, resorts, departing, adults, children, duration, schema_id, email_address, description, 
	client_list, departure_date, offers_by_month, de_duplicate_offers, trip_type, enabled, fields, token) => {
	let str_dep_date = null;
	if(departure_date != null){
		str_dep_date = '"' + departure_date + '"';
	}

	client_list = '[]';
	
    let q = `mutation {product_feed_update(feed_id: ${feed_id},name: "${name}", destinations: ${destinations}, regions: ${regions}, resorts: ${resorts}, departing: ${departing},
	adults:${adults}, children: ${children}, duration: ${duration}, schema_id: ${schema_id}, email_address: "${email_address}", description: "${description}", 
	departure_date: ${str_dep_date}, offers_by_month:${offers_by_month}, de_duplicate_offers: ${de_duplicate_offers}, trip_type: ${trip_type}, client_acc_list: ${client_list}, enabled: ${enabled}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

// export const product_feed_remove = async(margin_id, fields, token) => {
//     let q = `mutation {margin_remove(margin_id: ${margin_id}) {
// 		result {
// 			${fields}
// 		}
// 		error {
// 			... on Unexpected_Error {
// 				message
// 			}
// 		}
// 	}}`

//     let x = await graph2(q, token)
//     return x
// }