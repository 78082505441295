import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {product_feeds} from '../../service/product_feed'



const Margins = ({user_token, set_menu, authorize}) => {

    const [d_view, setDataView] = useState(null);

    useEffect(() =>{

		authorize(1);

		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/product_feeds/add', 'name': 'Create Product Feed'}]
		
		set_menu(lnks)

		let f = "id name created_by created description enabled schema_id ";

		let x = product_feeds(f, user_token).then((r) =>{
			if(r == null){
				return
			}	
			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
			}
			if(r.product_feeds.error != null){
				alert(r.product_feeds.error.message)
				return
			}
			setDataView(r.product_feeds.result)
		}
		);
    }, [])

	// const remove = async (id) => {
	// 	let f = "id name created_by created description enabled schema_id ";

	// 	const r = await margin_remove(id, f, user_token);
	// 	if(r.errors != null){
	// 		if(r.errors.length > 0){
	// 			alert(r.errors[0].message)
	// 			return
	// 		}
	// 		alert("Unexpected Error Contact HWT support...")
	// 		return
	// 	}
	// 	if(r.margin_remove.error != null){
	// 		alert(r.margin_remove.error.message)
	// 		return
	// 	}
	// 	setDataView(r.margin_remove.result)
	// }

    let tbl_view = null;
	if(d_view != null){

        if(d_view.length == 0){
            return (<div>
                <div className='nothing_here'>
                    Nothing to see here <br></br>
                    It looks like you have no product feeds setup. Click <a href='/product_feeds/add'>here</a> to create the first.
                </div>
            </div>)
        }

		let rows = []
		for(let x of d_view){

			let str_en = 'Disabled';
			if(x.enabled){
				str_en = 'Enabled';
			}

			let created_date = dayjs(x.created, "YYYY-MM-DD HH:mm");
			let str_created = created_date.format('DD/MM/YYYY HH:mm')

			rows.push(<tr>
				<td>
					{x.name}
				</td>
				<td>
					{x.description}
				</td>
				<td>
					{x.created_by}
				</td>
				<td>
					{str_created}
				</td>
				<td>
					{'FACEBOOK'}
				</td>	
				<td>
					{str_en}
				</td>
				<td>
					<a href={'/product_feeds/add?pfid=' + x.id}>Edit</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Name
					</td>
					<td>
						Description 
					</td>
					<td>
						Created by
					</td>
					<td>
						Created
					</td>
					<td>
						Schema
					</td>
					<td>
						Enabled
					</td>
					<td></td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

    return(<div className='rule_tbl'>
        {tbl_view}
    </div>);


};

export default Margins;